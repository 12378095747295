<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="展示仓库">
              <a-select
                option-label-prop="tt"
                show-search
                allow-clear
                @keyup.enter.native="$refs.table.refresh(true)"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                v-model="queryParam.exhibition_warehouse_id"
                placeholder="展示仓库"
                :default-active-first-option="false"
                @search="handleExhibitionWarehouseSearch"
                @change="handleExhibitionWarehouseChange"
              >
                <a-select-option v-for="d in dataSource_exhibition_warehouse" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="展示货物">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                @keyup.enter.native="$refs.table.refresh(true)"
                v-model="queryParam.exhibition_goods_id"
                placeholder="展示货物"
                :default-active-first-option="false"
                @search="handleExhibitionGoodsSearch"
                @change="handleExhibitionGoodsChange"
              >
                <a-select-option v-for="d in dataSource_exhibition_goods" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="保税商品">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                @keyup.enter.native="$refs.table.refresh(true)"
                v-model="queryParam.bonded_commodity_id"
                placeholder="保税商品"
                @search="handleBondedCommoditySearch"
                @change="handleBondedCommodityChange"
              >
                <a-select-option v-for="d in dataSource_bonded_commodity" :key="d.id" :tt="d.bonded_goods.name + '(' + d.batch+ ')'">
                  {{ d.bonded_goods.name + '(' + d.batch+ ')' }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 70px; max-width: 80px">
        {{ text }}
      </div>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 100px">
        {{ (text / 100).toFixed(2) }}
      </div>
      <span slot="img" slot-scope="img, record">
        <a-avatar
          v-if="(getFileSuffix(img) !== 'pdf') && img"
          slot="img"
          :src="img"
          shape="square"
          @click="openPreview(img)"
        />
        <a :href="img" v-if="getFileSuffix(img) === 'pdf'" target="_blank">{{ record.name }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleQid(record)">QID</a>
          <a-divider type="vertical" />
          <a @click="handleRecord(record)">查看</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="图片错误" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'

import {
  exhibition_commodity_create,
  exhibition_commodity_delete,
  exhibition_commodity_list
} from '@/api/exhibition_commodity'
import { exhibition_goods_list } from '@/api/exhibition_goods'
import { exhibition_warehouse_list } from '@/api/exhibition_warehouse'
import { bonded_commodity_list } from '@/api/bonded_commodity'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      exhibition_warehouse_value: '',
      exhibition_goods_value: '',
      bonded_commodity_value: '',
      dataSource_exhibition_warehouse: [],
      dataSource_exhibition_goods: [],
      dataSource_bonded_commodity: [],
      previewvisible: false,
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '展示仓库',
          dataIndex: 'exhibition_warehouse'
        },
        {
          title: '成本',
          align: 'center',
          scopedSlots: { customRender: 'money_render' },
          dataIndex: 'cost'
        },
        {
          title: '吊牌价',
          align: 'center',
          scopedSlots: { customRender: 'money_render' },
          dataIndex: 'tag_price'
        },
        {
          title: '售价',
          align: 'center',
          scopedSlots: { customRender: 'money_render' },
          dataIndex: 'sales_price'
        },
        {
          title: '展览总数',
          dataIndex: 'total_exhibition_count',
          align: 'center',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '图片',
          dataIndex: 'img',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '350px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      previewurl: '',
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('电费参数', parameter)
        return exhibition_commodity_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return data
          })
      }
    }
  },
  created () {},
  methods: {
    handleExhibitionWarehouseSearch (value) {
      exhibition_warehouse_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_exhibition_warehouse = !value ? [] : result
      })
    },
    handleExhibitionWarehouseChange (value) {
        this.queryParam.exhibition_warehouse_id = value
        this.exhibition_warehouse_value = value
    },

    handleExhibitionGoodsSearch (value) {
      exhibition_goods_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_exhibition_goods = !value ? [] : result
      })
    },
    handleExhibitionGoodsChange (value) {
      this.queryParam.exhibition_goods_id = value
    },

    handleBondedCommoditySearch (value) {
      bonded_commodity_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        console.log(result, '98989')
        this.dataSource_bonded_commodity = !value ? [] : result
      })
    },
    handleBondedCommodityChange (value) {
      this.queryParam.bonded_commodity_id = value
      this.bonded_commodity_value = value
    },
    getFileSuffix: function (filename) {
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    checkFileType: function (suffix) {
      var type = ''
      switch (suffix) {
        case 'mp4':
          type = 'mp4'
          break
        case 'ppt':
        case 'pptx':
        case 'doc':
        case 'docx':
        case 'pdf':
          type = 'office'
          break
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'bmp':
        case 'gif':
          type = 'pic'
          break
      }
      return type
    },
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    },
    time (dates, dateStrings) {
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/exhibition/commodity/index/' + record.id })
    },
    handleUpdate (record) {
      console.log(record)
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      exhibition_commodity_delete(record.id).then((res) => {
        this.$refs.table.refresh()
      })
    },
    handleQid (record) {
      this.$router.push('/exhibition/commodity/qid/' + record.id)
    },
    handleRecord (record) {
      this.$router.push('/exhibition/commodity/record/' + record.id)
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.cost = Math.round(values.cost * 100)
          values.tag_price = Math.round(values.tag_price * 100)
          values.sales_price = Math.round(values.sales_price * 100)
          exhibition_commodity_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
