<template>
  <a-card bordered="false">
    <a-form :form="form" style="width: 60%; margin: 0 auto;">
      <a-row :gutter="[16,16]">
        <a-col :span="10" :offset="3">
          <a-form-item label="售价">
            <a-input type="number" step="0.01" v-decorator="['sales_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="吊牌价">
            <a-input type="number" step="0.01" v-decorator="['tag_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="图片" v-show="false">
            <a-textarea v-decorator="['img', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="图片">
            <div class="clearfix">
              <a-upload
                v-decorator="[
                  'img_all',
                  {
                    valuePropName: 'img_all',
                    rules: [{required: false, message: '必填项，请填写信息'}]
                  },
                ]"
                name="file"
                accept="image/png, image/jpeg, image/jpg"
                :action="actionUrl"
                :data="new_multipart_params"
                list-type="picture-card"
                :file-list="fileList"
                :before-upload="beforeUpload"
                @preview="handlePreview"
                @change="handleChange"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    Upload
                  </div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="图片错误" style="width: 100%" :src="previewImage || ''" />
              </a-modal>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item
        v-bind="buttonCol"
      >
        <a-row>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="10">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
          <a-col span="8"></a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { oss_token_list } from '@/api/oss_token'
import { exhibition_commodity_list, exhibition_commodity_update } from '@/api/exhibition_commodity'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      ImgUrl: '',
      fileList: [],
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0
    }
  },
  beforeCreate () {
    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
  },
  mounted () {
    this.$nextTick(() => {
      // this.loadEditInfo(this.record)
      exhibition_commodity_list({ id: this.$route.params.id })
        .then(({ data }) => {
          console.log(data, '我是数据列表@')
          const list = data.entries
          if (list && list.length > 0) {
            this.loadEditInfo(list[0])
          }
        })
    })
  },
  methods: {
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      console.log('我要预览', file.preview)
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.form.setFieldsValue({ img: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ img: this.actionUrl + '/' + this.new_multipart_params.key })
      }
    },
    beforeUpload (file) {
      this.new_multipart_params.key = file.uid + '_' + file.name
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      return isJpgOrPng && isLt5M
    },
    handleCancel (file) {
      this.previewVisible = false
      console.log(file, '我是删除')
    },
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        values.tag_price = Math.round(values.tag_price * 100)
        values.sales_price = Math.round(values.sales_price * 100)
        exhibition_commodity_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
            console.log(res, '修改成功')
    })
      })
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'tag_price', 'sales_price', 'img'])
        console.log('formData', formData)
        this.id = formData.id
        this.fileList = formData.img
          ? [{
          url: formData.img,
          uid: '1',
          name: 'xxx.png',
          status: 'done',
          response: 'Server Error 500'
        }]
          : []
        formData.tag_price = (formData.tag_price / 100).toFixed(2)
        formData.sales_price = (formData.sales_price / 100).toFixed(2)
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
