var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"bordered":"false"}},[_c('a-form',{staticStyle:{"width":"60%","margin":"0 auto"},attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":10,"offset":3}},[_c('a-form-item',{attrs:{"label":"售价"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sales_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['sales_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],attrs:{"type":"number","step":"0.01"}})],1),_c('a-form-item',{attrs:{"label":"吊牌价"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tag_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['tag_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],attrs:{"type":"number","step":"0.01"}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"图片"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['img', {rules: [{required: false, message: '必填项，请填写信息'}]}]),expression:"['img', {rules: [{required: false, message: '必填项，请填写信息'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"图片"}},[_c('div',{staticClass:"clearfix"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'img_all',
                {
                  valuePropName: 'img_all',
                  rules: [{required: false, message: '必填项，请填写信息'}]
                },
              ]),expression:"[\n                'img_all',\n                {\n                  valuePropName: 'img_all',\n                  rules: [{required: false, message: '必填项，请填写信息'}]\n                },\n              ]"}],attrs:{"name":"file","accept":"image/png, image/jpeg, image/jpg","action":_vm.actionUrl,"data":_vm.new_multipart_params,"list-type":"picture-card","file-list":_vm.fileList,"before-upload":_vm.beforeUpload},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[(_vm.fileList.length < 1)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" Upload ")])],1):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"图片错误","src":_vm.previewImage || ''}})])],1)])],1)],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.buttonCol,false),[_c('a-row',[_c('a-col',{attrs:{"span":"6"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1),_c('a-col',{attrs:{"span":"10"}},[_c('a-button',{on:{"click":_vm.handleGoBack}},[_vm._v("返回")])],1),_c('a-col',{attrs:{"span":"8"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }